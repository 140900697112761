@font-face {
  font-family: Inter;
  src: url("../public/Inter-Regular.ttf");
}

@font-face {
  font-family: Inter;
  src: url("../public/Inter-SemiBold.ttf");
  font-weight: bold;
}

html,
body {
  --custom-font-family: Inter;
  --sb-white: #fff;
  --sb-lightest-gray: #f5f5f5;
  --sb-light-gray: #f0f0ef;
  --sb-medium-gray: #d0d1d2;
  --sb-medium-darker-gray: #b7b7b7;
  --sb-medium-dark-gray: #a1a1a3;
  --sb-darker-gray: #555;
  --sb-dark-gray: #0f0e12;

  --sb-gray-0: #e0dfe1;
  --sb-gray-1: #dbdddc;
  --sb-gray-2: #38434c;
  --sb-blue-1: #2e4e75;
  --sb-blue-0: #427398;
  --sb-yellow: #daa74b;
  --sb-orange: #df6236;
  --sb-red: #c72038;
  --sb-green: #77dd86;
  --sb-green-bg: rgba(119, 221, 134, 0.125);
  --sb-green-color: #294d2e;

  --sb-color-quiet: var(--sb-blue-0);

  --sb-spacing: 0.25rem;
  --sb-rounded: 0.25rem;
  --sb-bg-color: var(--sb-medium-gray);
  --sb-panel-color: var(--sb-medium-gray);
  --sb-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  --sb-color: var(--sb-gray-2);
  --sb-color-secondary: var(--sb-medium-dark-gray);
  --sb-border: 1px solid var(--sb-medium-gray);

  --sb-button-bg: var(--sb-dark-gray);
  --sb-button-color: var(--sb-lightest-gray);

  padding: 0;
  margin: 0;
  font-family: var(--custom-font-family);
  color: var(--sb-color);
  background-color: var(--sb-light-gray);
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

* {
  font-family: var(--custom-font-family);
  box-sizing: border-box;
}

a {
  color: var(--sb-gray-2);
  text-decoration: none;
}

label {
  display: block;
  margin: 5px 0;
  color: var(--sb-color-secondary);
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 1rem;
}

input {
  width: 100%;
  border-radius: 5px;
  border: solid 1px var(--sb-medium-gray);
  padding: 8px;
  background-color: var(--sb-lightest-gray);
  color: var(--sb-color);
}

input[disabled] {
  color: var(--sb-color-secondary);
}

input[readonly] {
  color: var(--sb-medium-dark-gray);
}
select,
textarea,
input[type="text"],
input[type="number"],
input {
  font-size: 16px;
}
select:focus,
textarea:focus,
input:focus {
  font-size: 16px;
}

h3 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

/* Grid */

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}
.col-1-sm {
  width: 4.33%;
}
.col-2-sm {
  width: 12.66%;
}
.col-3-sm {
  width: 21%;
}
.col-4-sm {
  width: 29.33%;
}
.col-5-sm {
  width: 37.66%;
}
.col-6-sm {
  width: 46%;
}
.col-7-sm {
  width: 54.33%;
}
.col-8-sm {
  width: 62.66%;
}
.col-9-sm {
  width: 71%;
}
.col-10-sm {
  width: 79.33%;
}
.col-11-sm {
  width: 87.66%;
}
.col-12-sm {
  width: 96%;
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.hidden-sm {
  display: none;
}

@media only screen and (min-width: 33.75em) {
  /* 540px */
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 45em) {
  /* 720px */
  .col-1 {
    width: 4.33%;
  }
  .col-2 {
    width: 12.66%;
  }
  .col-3 {
    width: 21%;
  }
  .col-4 {
    width: 29.33%;
  }
  .col-5 {
    width: 37.66%;
  }
  .col-6 {
    width: 46%;
  }
  .col-7 {
    width: 54.33%;
  }
  .col-8 {
    width: 62.66%;
  }
  .col-9 {
    width: 71%;
  }
  .col-10 {
    width: 79.33%;
  }
  .col-11 {
    width: 87.66%;
  }
  .col-12 {
    width: 96%;
  }
  .hidden-sm {
    display: block;
  }
}

@media only screen and (min-width: 60em) {
  /* 960px */
  .container {
    width: 75%;
    max-width: 60rem;
  }
}

/* Forms */

/* Utils */

.block {
  display: block;
  width: 100%;
}
.inline-block {
  display: inline-block;
  width: 100%;
}
.flex {
  display: flex;
}
.flex.column {
  flex-direction: column;
}
.flex.row {
  flex-direction: row;
}
.flex.flex-1 {
  flex: 1 1 0;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.text-sm {
  font-size: 0.8rem;
  font-weight: 300;
}
.text-right {
  text-align: right;
}
.font-light {
  font-weight: 300;
}
.opacity-half {
  opacity: 50%;
}

/* Button */

button,
.button {
  color: var(--sb-button-color);
  border: none;
  background-color: var(--sb-button-bg);
  display: inline-block;
  text-align: center;
  border-radius: var(--sb-rounded);
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
}

button.primary,
.button.primary {
  background-color: var(--sb-color-brand);
  border: 1px solid var(--sb-color-brand);
}

/* Widgets */

.card {
  width: 100%;
  display: block;
  border: var(--sb-border);
  border-radius: var(--sb-rounded);
  padding: var(--sb-spacing);
}

.avatar {
  border-radius: var(--sb-rounded);
  overflow: hidden;
  max-width: 100%;
}
.avatar.image {
  object-fit: cover;
}
.avatar.no-image {
  background-color: #333;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}

.footer {
  position: absolute;
  max-width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row;
  border-top: var(--sb-border);
  background-color: var(--sb-bg-color);
}
.footer div {
  padding: var(--sb-spacing);
  display: flex;
  align-items: center;
  width: 100%;
}
.footer div > img {
  height: 20px;
  margin-left: 10px;
}
.footer > div:first-child {
  display: none;
}
.footer > div:nth-child(2) {
  justify-content: left;
}
.mainHeader {
  width: 100%;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.avatarPlaceholder {
  border: var(--sb-border);
  border-radius: var(--sb-rounded);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-widget {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-widget > .button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--sb-bg-color);
  text-transform: none !important;
  transition: all 0.2s ease;
}

.form-widget .button:hover {
  background-color: var(--sb-bg-color);
}

.form-widget .button > .loader {
  width: 17px;
  animation: spin 1s linear infinite;
  filter: invert(1);
}

.mapSearchWrapper {
  position: relative;
}
.mapSearchInput {
  width: 100%;
  padding: 4px;
}
.mapSearchResults {
  display: none;
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 9999;
  background: #ffffff;
  border: 1px #cccccc solid;
  font-family: sans-serif;
}
.mapSearchResultsItem {
  padding: 4px;
  border-bottom: 1px #cccccc solid;
}
.mapSearchResultsItem:hover {
  background: #eeeeee;
}

.page-row {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: auto;
  position: relative;
  background: var(--sb-lightest-gray);
}
